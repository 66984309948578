<template>
  <b-modal
    id="attendance-files-modal"
    hide-header
    hide-footer
    no-stacking
    centered
    size="lg"
  >
    <iframe title="Arquivos da consulta" :src="this.url" />
  </b-modal>
</template>

<script>
export default {
  name: 'FilesModal',
  props: {
    url: String
  }
}
</script>

<style lang="scss">
#attendance-files-modal {
  .modal-body {
    // TO DO - Altura responsiva
    height: 600px;
    iframe {
      width: 100%;
      height: 95%;
      border: none;
    }
  }
}
</style>
Footer
