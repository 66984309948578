<template>
  <div class="attendance-file-process-wrapper">
    <div v-show="processing || printing">
      <div class="doc" />
      <!-- <b-spinner  class="loading" variant="info" type="grow" /> -->
    </div>
    <FilesModal :url="url" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { showDoctorOverview } from '@/utils/permissionsHelp'
import { mergePrintableAllPDFs } from '@/utils/PDFHelper'
import FilesModal from './FilesModal.vue'

export default {
  components: {
    FilesModal,
  },
  computed: {
    ...mapGetters('pusher', ['channel']),
  },
  mounted() {
    if (showDoctorOverview()) {
      this.channel.bind('doc_started', this.started, this)
      this.channel.bind('doc_finished_printable', this.finishedPrintable, this)
      this.channel.bind('doc_finished_all', this.finishedAll, this)
      this.channel.bind('doc_failed', this.failed, this)
    }
  },
  // mounted() {
  //   if (!this.channel && showDoctorOverview()) {
  //     this.channel = this.pusher.subscribe(`attendance-process-${this.clinic.id}`)
  //     this.channel.bind('started', this.started, this)
  //     this.channel.bind('finished_printable', this.finishedPrintable, this)
  //     this.channel.bind('finished', this.finished, this)
  //     this.channel.bind('failed', this.failed, this)
  //   }
  // },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      person: JSON.parse(localStorage.getItem('user')),
      processing: false,
      printing: false,
      hasFailure: false,
      url: null,
      files: [],
      multiple: null,
      messages: [],
      teste: false,
    }
  },
  methods: {
    openModal() {
      if (this.url) {
        document.getElementById('btnFiles').click()
      }
    },
    started(data) {
      if (this.person.id === data.professional_id) {
        this.processing = true
      }
    },
    async finishedPrintable(pusherData) {
      if (this.person.id === pusherData.identifier) {
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        data = JSON.parse(data.payload)
        await this.getAttendanceFiles(data.attendance_id, data.toPrint);
      }
    },
    finishedAll(pusherData) {
      if (this.person.id === pusherData.identifier) {
        this.processing = false
      }
    },
    async failed(pusherData) {
      if (this.person.id === pusherData.identifier) {
        this.processing = false
        if (this.clinic.type === 'BV' || process.env.NODE_ENV !== 'production') {
          this.makeToast()
        }
      }
    },
    async getAttendanceFiles(attendanceId, toPrint) {
      if (!toPrint.length) return;
      this.printing = true;
      const res = await this.api.getAttendanceFiles(attendanceId);
      const attendanceFiles = res.data.filter(el => toPrint.includes(el.type_id));
      if(this.teste === false) {
        this.teste = true
        const url = await mergePrintableAllPDFs(attendanceFiles);
        this.openDocument(url);
      }
      this.printing = false;
    },
    openDocument(url) {
      window.open('about:blank').document.write(
        `<html>
          <body style="margin:0;">
            <iframe width="100%" height="100%" src="${url}" style="border:none" >
          </body>
        </html>
      `
      )
      this.teste = false
    },
    makeToast() {
      this.toastCount++
      this.$bvToast.toast(`Falha ao assinar documento! Clique aqui para mais detalhes.`, {
        variant: 'danger',
        title: 'Falha de assinatura',
        appendToast: true,
        solid: true,
        noAutoHide: false,
        href: `/log-documentos`,
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.attendance-file-process-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.status {
  font-size: 1.5vh;
  .btn {
    font-size: 1.5vh;
  }
}
.loading {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}
.icon {
  width: 10%;
  height: 10%;
}

.doc {
  width: 50px;
  height: 50px;
  background-image: url('../../assets/images/doc_processing.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
}
</style>
